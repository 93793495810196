<template>
  <div>
    <div class="box box--sm box--gray radius-bottom-0 mt-16 d-flex">
      <multiple-select :value="filterValues" :options="options" @filter-callback="onGetRequestLogsByFilter($event)" />

      <selection-box
        v-model="params.perPage"
        :options="pagerOptions"
        :is-align-right="true"
        :on-check="onGetRequestLogs"
      />
    </div>

    <div
      v-if="requestLogs && requestLogs.data && requestLogs.data.length"
      class="table-wrapper table-wrapper--fluid table-wrapper--expand radius-top-0"
    >
      <table class="table table--striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Add Time</th>
            <th>Type</th>
            <th>Status</th>
            <th>Error Message</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="log in requestLogs.data" :key="log.id">
            <td class="wr-20 text-capitalize">{{ log.id }}</td>
            <td class="wr-20 text-capitalize">{{ log.addTime | moment_lts }}</td>
            <td class="wr-20 text-capitalize">{{ log.type | format_request_log_type_text }}</td>
            <td class="wr-20 text-capitalize" v-html="formatRequestLogStatus(log.status)" />
            <td>{{ log.error || '_' }}</td>
          </tr>
        </tbody>
      </table>

      <div class="text-center mt-32">
        <p>{{ requestLogs.total | format_order_count_text }} in total</p>

        <pagination
          v-if="isNextPage(requestLogs.total)"
          v-model="page"
          :page-count="getPageCount(totalPages, requestLogs.total)"
          :page-range="5"
          :click-handler="clickPagerCallback"
        />
      </div>
    </div>

    <p v-else class="mt-16">No data</p>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { PAGER_OPTIONS, REQUEST_LOG_STATUS_OPTIONS, REQUEST_LOG_TYPE_OPTIONS } from '@/utils/data-sets'
import toggleLoading from '@/mixins/toggle-loading'
import pagination from '@/mixins/pagination'
import handleAssets from '@/mixins/handle-assets'
import formatRequestLogStatus from './-mixins/request-log-status'
const MultipleSelect = () => import('@/components/MultipleSelect')
const Pagination = () => import('@/components/Pagination')
const SelectionBox = () => import('@/components/SelectionBox')
import { deepCopy } from '@/utils/json-parser'

export default {
  data() {
    return {
      options: [
        {
          label: 'Type',
          options: REQUEST_LOG_TYPE_OPTIONS
        },
        {
          label: 'Status Filter',
          options: REQUEST_LOG_STATUS_OPTIONS
        }
      ],
      filterValues: [],
      params: {
        perPage: 10
      },
      page: 1,
      pagerOptions: PAGER_OPTIONS
    }
  },

  mixins: [toggleLoading, pagination, handleAssets, formatRequestLogStatus],

  components: {
    SelectionBox,
    Pagination,
    MultipleSelect
  },

  computed: {
    ...mapState('requestLog', ['requestLogs', 'assets']),

    totalPages() {
      if (this.requestLogs && this.requestLogs.total > 0) {
        return Math.ceil(this.requestLogs.total / this.params.perPage)
      }

      return 0
    },

    statuses() {
      let statuses = {}
      const statusFilters = this.filterValues.filter(item => item.category === 'status')
      if (
        this.filterValues.length <= 0 ||
        statusFilters.length <= 0 ||
        (statusFilters.length === 1 && statusFilters[0].value === 0)
      ) {
        const options = deepCopy(REQUEST_LOG_STATUS_OPTIONS)
        options.shift()
        options.forEach((item, index) => {
          statuses[`status[${index}]`] = item.value
        })
      } else {
        this.filterValues.forEach((item, index) => {
          statuses[`status[${index}]`] = item.value
        })
      }

      return statuses
    }
  },

  methods: {
    ...mapActions('requestLog', ['getRequestLogs']),

    onGetRequestLogs() {
      this.toggleLoading()
      this.page = 1
      this.getRequestLogs(this.getRequestLogsParams()).finally(() => {
        this.toggleLoading()
      })
    },

    onGetRequestLogsByFilter(values) {
      this.filterValues = values
      const type = values.filter(value => value.category === 'type')
      if (type.length > 0) {
        this.params.type = type[0].value
      }
      this.onGetRequestLogs()
    },

    clickPagerCallback() {
      this.toggleLoading()
      this.getRequestLogs(this.getRequestLogsParams()).finally(() => {
        this.toggleLoading()
      })
    },

    getRequestLogsParams() {
      const params = {
        ...this.statuses,
        page: this.page,
        limit: this.params.perPage,
        type: this.params.type
      }

      return params
    }
  },

  created() {
    this.getRequestLogs(this.getRequestLogsParams()).finally(() => {
      this.toggleLoading()
    })
  }
}
</script>
