import { REQUEST_LOG_STATUSES } from '@/utils/key-mapping'

export default {
  methods: {
    formatRequestLogStatus(status) {
      let statusEl

      switch (status) {
        case REQUEST_LOG_STATUSES.invalid:
          statusEl = '<p class="text-uppercase">Invalid</p>'
          break
        case REQUEST_LOG_STATUSES.parseJson:
          statusEl = '<p class="text-uppercase">Parse Json</p>'
          break
        case REQUEST_LOG_STATUSES.waitingAdmin:
          statusEl = '<p class="text-bold text-warning text-uppercase">waiting admin free</p>'
          break
        case REQUEST_LOG_STATUSES.transferERC20:
          statusEl = '<p class="text-bold text-primary text-uppercase">transfer erc20</p>'
          break
        case REQUEST_LOG_STATUSES.dialAssetService:
          statusEl = '<p class="text-bold text-uppercase text-primary">dial assets service</p>'
          break
        case REQUEST_LOG_STATUSES.dialUserService:
          statusEl = '<p class="text-bold text-primary text-uppercase">dial user service</p>'
          break
        case REQUEST_LOG_STATUSES.fail:
          statusEl = '<p class="text-bold text-error text-uppercase">Failed</p>'
          break
        case REQUEST_LOG_STATUSES.dialEventService:
          statusEl = '<p class="text-bold text-primary text-uppercase">dial event service</p>'
          break
        case REQUEST_LOG_STATUSES.addFeedOracle:
          statusEl = '<p class="text-bold text-primary text-uppercase">add feed oracle</p>'
          break
        case REQUEST_LOG_STATUSES.updateInterestRate:
          statusEl = '<p class="text-boldt text-primary text-uppercase">add interest rate</p>'
          break
        case REQUEST_LOG_STATUSES.dialProgramService:
          statusEl = '<p class="text-boldt text-primary text-uppercase">dial program service</p>'
          break
        case REQUEST_LOG_STATUSES.convertTokoTransfer:
          statusEl = '<p class="text-boldt text-primary text-uppercase">convert toko transfer</p>'
          break
      }

      return statusEl
    }
  }
}
